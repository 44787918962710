(function() {
    'use strict';

    angular
        .module('app.translate')
        .config(translateConfig)
        ;
    translateConfig.$inject = ['$translateProvider', '$translatePartialLoaderProvider'];
    function translateConfig($translateProvider, $translatePartialLoaderProvider){

      /*$translateProvider.useStaticFilesLoader({
          prefix : 'translate/',
          suffix : ''
      });*/
      $translatePartialLoaderProvider.addPart(' ');
      $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: '/translate/{lang}/{part}'
      });



      $translateProvider.preferredLanguage('es');
      $translateProvider.useLocalStorage();
      $translateProvider.usePostCompiling(true);
      $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

    }
})();